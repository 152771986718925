<template>
	<Modal :key="modalKey" v-if="showModal" :modal-type="modalType"></Modal>

	<Header :all-data="allData"></Header>

	<div class="main" v-show="loading">
		<Loading></Loading>
	</div>
	<div class="main">
		<div class="banner">
			<div class="banner__container p-20">
				<div class="banner__trophy m-r-15">
					<RankTrophy :rank="1" :size="60"></RankTrophy>
				</div>

				<div class="banner__text p-l-10 p-r-10">
					<p class="m-b-5">
						The Web Vitals Index was created to show which e-commerce businesses take web performance and user experiences seriously. Great websites
						help generate more revenue and convert more customers.
					</p>
					<p>
						<strong>
							A WVI score of 80+ is considered to be very good.
						</strong>
					</p>
				</div>
			</div>
		</div>

		<Controls :all-data="allData"></Controls>
		<IndexTable :all-data="allData"></IndexTable>
	</div>
	<Footer :all-data="allData"></Footer>
</template>

<script>
// Components Import
import Header from "./Header/Header.vue";
import Controls from "./Controls/Controls.vue";
import IndexTable from "./IndexTable/IndexTable.vue";
import Loading from "../Utilities/Loading.vue";
import Footer from "./Footer/Footer.vue";
import Modal from "../Utilities/Modal/Modal.vue";
import RankTrophy from "./IndexTable/Rank/RankTrophy.vue";

// Services, Events etc Import
import { loadingEventsEmitter, industryEventsEmitter, searchEventsEmitter, modalEventsEmitter, events } from "@/events/events";
import { WebVitalsIndexService, allDefault } from "@/services/wvi.service";

export default {
	components: {
		Header,
		Controls,
		IndexTable,
		Loading,
		Footer,
		Modal,
		RankTrophy,
	},
	data() {
		return {
			loading: false,
			showModal: false,
			modalKey: 0,
			modalType: "",
			selectedIndustry: "",
			allData: {},
		};
	},
	methods: {
		async getData() {
			const wviService = new WebVitalsIndexService();

			if (this.selectedIndustry === "") {
				this.selectedIndustry = { label: "All", token: allDefault };
			}

			this.allData = await wviService.getData(this.selectedIndustry.token);
		},
	},
	created() {
		// get data in here and pass into components instead of doing multiple requests
		this.getData();
	},
	mounted() {
		modalEventsEmitter.on(events.MODAL_CHANGE, (payload) => {
			this.modalKey++;
			this.modalType = payload.type;
			this.showModal = payload.show;
		});

		industryEventsEmitter.on(events.INDUSTRY, (payload) => {
			this.selectedIndustry = payload;
			this.getData();
		});

		searchEventsEmitter.on(events.SEARCH_EMPTY, () => {
			this.selectedIndustry = allDefault;
			this.getData();
		});

		loadingEventsEmitter.on(events.LOADING, (payload) => {
			this.loading = payload;
		});
	},
};
</script>

<style>
/* .fade-in {
	transition: opacity 2s ease-in;
} */
</style>
