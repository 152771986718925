<template>
	<div class="footer">
		<div class="footer__right">
			<div class="sz-12 fw-l" v-if="!data.noData">Data Generated On: {{ generatedDate }}</div>
		</div>

		<div class="cta">
			<span class="sz-18 p-5 ta-c tc-white">Not listed? Add your site to the index</span>
			<div class="cta__button-link sz-18 tc-ok fw-b hover-light" @click="claimYourPlace()">
				<span>Claim Your Place</span>
				<span>
					<Svg :svg-type="svgType.GENERAL" svg-name="position_arrow" svg-classes="sz-14 flc-ok" class="m-t-5 m-r-5"></Svg>
				</span>
			</div>
		</div>

		<div class="blogs">
<!--			<span class="tc-white-1"-->
<!--				>Check out our-->
<!--				<a class="basic blogs__link" href="https://blog.webvitalsindex.com/" target="_blank" rel="nofollow">blogs</a>-->
<!--			</span>-->
			<span class="blogs__top tc-white-1 hover-link" @click="scrollToTop()">
				Back to the top <Svg :svg-type="svgType.GENERAL" svg-name="position_arrow" svg-classes="sz-12 flc-white-1 m-l-5"></Svg>
			</span>
		</div>

		<div class="powered-by">
			<div class="powered-by__row tc-white-1 sz-16 m-b-5">
				The Web Vitals Index is powered by
				<a href="https://rapidspike.com" target="_blank" rel="nofollow">
					<Svg :svg-type="svgType.GENERAL" svg-name="rsLogoFull" svg-classes="sz-150 m-l-5 m-t-5"></Svg>
				</a>
			</div>
			<div class="powered-by__copyright tc-white-1 sz-12">
				Copyright &#169; 2021 - All Rights Reserved.
			</div>
		</div>
	</div>
</template>

<script>
// Components
import Svg from "../../Utilities/Svg.vue";

// Services etc
import { UtilsService } from "@/services/utils.service";
import { SvgService } from "@/services/svg.service";
import { WebVitalsIndexService, allDefault } from "@/services/wvi.service";
import { modalEventsEmitter, events } from "@/events/events.js";

export default {
	components: {
		Svg,
	},
	props: ["allData"],
	data() {
		return {};
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		data: function() {
			return this.allData;
		},
		generatedDate: function() {
			if (this.data.currentWeekGeneratedDate) {
				return this.data.currentWeekGeneratedDate.friendly;
			}
			return "";
		},
	},
	methods: {
		claimYourPlace() {
			const type = "claim";
			const show = true;
			modalEventsEmitter.emit(events.MODAL_CHANGE, { type, show });

			if (window.innerWidth < 1100) {
				this.scrollToTop();
			}
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	},
};
</script>
