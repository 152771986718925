<template>
	<div id="info-panel" class="info-panel">
		<div class="info-panel__row">
			<div class="info-panel__row--rank p-tb-8 p-lr-10">
				<slot name="trophy"></slot>
			</div>
			<div class="info-panel__row--position p-tb-8 p-lr-10">
				<slot name="position"></slot>
			</div>
			<div class="info-panel__row--company p-tb-8 p-lr-10">
				<div class="company__row">
					<div class="comapny__row--label-container">
						<a :href="website.domain" target="_blank" rel="noopener nofollow" class="m-r-10 company__favicon">
							<img height="20" width="20" class="m-r-10 company__favicon--img" :src="getFavicon(website.domain)" :alt="website.label" />
						</a>
						<span class="company__label m-r-15 hover-cursor" @click="closeInfo()">
							<h3>
								{{ website.label }}
							</h3>
						</span>
					</div>
					<span class="company__domain">
						<p>
							<a class="basic" :href="website.domain" target="_blank" rel="nofollow">{{ website.domain }}</a>
						</p>
					</span>
				</div>
			</div>
			<div class="info-panel__row--close p-tb-8 p-lr-10 hover-cursor" @click="closeInfo()">
				<slot name="industry"></slot>
				<div @click="closeInfo()" class="info-panel__row--close--postion">
					<Svg
						@click="closeInfo()"
						class="hover-fill-ok flip-y info-panel__close"
						:svg-type="svgType.GENERAL"
						svg-name="toggle_arrow"
						svg-classes="sz-18 flc-mid-4"
					></Svg>
				</div>
			</div>
		</div>

		<div class="info-panel__data">
			<div class="info-panel__lighthouse">
				<div class="lighthouse__item">
					<StatGraph :value="website.data.lighthouse.performance" type="lighthouse" :mini="false"></StatGraph>
					<div>
						<div class="lighthouse__item--text">Performance</div>
						<div class="lighthouse__item--sub-text">Overall performance score</div>
					</div>
				</div>
				<div class="lighthouse__item">
					<StatGraph :value="website.data.lighthouse.accessibility" type="lighthouse" :mini="false"></StatGraph>
					<div>
						<div class="lighthouse__item--text">Accessibility</div>
						<div class="lighthouse__item--sub-text">
							How usable the site is for people of all abilities
						</div>
					</div>
				</div>
				<div class="lighthouse__item">
					<StatGraph :value="website.data.lighthouse.seo" type="lighthouse" :mini="false"></StatGraph>
					<div>
						<div class="lighthouse__item--text">SEO</div>
						<div class="lighthouse__item--sub-text">
							How optimised the site is for search engines
						</div>
					</div>
				</div>
				<div class="lighthouse__item">
					<StatGraph :value="website.data.lighthouse.best_practices" type="lighthouse" :mini="false"></StatGraph>
					<div>
						<div class="lighthouse__item--text">Best Practices</div>
						<div class="lighthouse__item--sub-text">
							How well the site code follows best practices
						</div>
					</div>
				</div>
			</div>

			<div class="info-panel__web-vitals">
				<div class="web-vitals__item">
					<div class="lighthouse__item m-l-4">
						<StatGraph :value="website.data.uptime" type="uptime" :mini="false"></StatGraph>
						<div>
							<div class="lighthouse__item--text">Uptime</div>
							<div class="lighthouse__item--sub-text">
								Monitors critical failures that made the site inaccessible
							</div>
						</div>
					</div>
				</div>
				<div class="web-vitals__item">
					<div class="web-vitals__indicator">
						<div class="web-vitals__indicator--icon" :class="{ 'web-vitals__indicator--icon--na': !checkWebVitalsScores('lcp') }">
							<Svg
								v-if="checkWebVitalsScores('lcp')"
								:svg-type="svgType.GENERAL"
								:svg-name="getIndicatorSetup('lcp').svgName"
								:svg-classes="getIndicatorSetup('lcp').svgClasses"
							></Svg>
							<span v-else class="sz-16 tc-white-1">N/A</span>
						</div>
					</div>
					<div class="ta-l">
						<div class="web-vitals__item--value" :class="getIndicatorSetup('lcp').textColour">
							{{ checkWebVitalsScores("lcp") ? website.data.webvitals.lcp.toFixed() : "N/A" }}
							<small v-if="this.checkWebVitalsScores('lcp')">ms</small>
						</div>
						<div class="lh-1-5">
							<div class="web-vitals__item--text">LCP <small>Largest Contentful Paint</small></div>
							<div class="web-vitals__item--sub-text">
								Measures when main content is loaded, find out more about LCP
								<a class="basic" href="https://web.dev/lcp/#what-is-lcp" target="_blank" rel="noopener nofollow">here</a>
							</div>
						</div>
					</div>
				</div>
				<div class="web-vitals__item">
					<div class="web-vitals__indicator">
						<div class="web-vitals__indicator--icon" :class="{ 'web-vitals__indicator--icon--na': !checkWebVitalsScores('fid') }">
							<Svg
								v-if="checkWebVitalsScores('fid')"
								:svg-type="svgType.GENERAL"
								:svg-name="getIndicatorSetup('fid').svgName"
								:svg-classes="getIndicatorSetup('fid').svgClasses"
							></Svg>
							<span v-else class="sz-16 tc-white-1">N/A</span>
						</div>
					</div>
					<div class="ta-l">
						<div class="web-vitals__item--value" :class="getIndicatorSetup('fid').textColour">
							{{ checkWebVitalsScores("fid") ? website.data.webvitals.fid.toFixed() : "N/A" }}
							<small v-if="this.checkWebVitalsScores('fid')">ms</small>
						</div>
						<div class="lh-1-5">
							<div class="web-vitals__item--text">FID <small>First Input Delay</small></div>
							<div class="web-vitals__item--sub-text">
								Tracks load responsiveness, find out more about FID
								<a class="basic" href="https://web.dev/fid/#what-is-fid" target="_blank" rel="noopener nofollow">here</a>
							</div>
						</div>
					</div>
				</div>
				<div class="web-vitals__item">
					<div class="web-vitals__indicator">
						<div class="web-vitals__indicator--icon" :class="{ 'web-vitals__indicator--icon--na': !checkWebVitalsScores('cls') }">
							<Svg
								v-if="checkWebVitalsScores('cls')"
								:svg-type="svgType.GENERAL"
								:svg-name="getIndicatorSetup('cls').svgName"
								:svg-classes="getIndicatorSetup('cls').svgClasses"
							></Svg>
							<span v-else class="sz-16 tc-white-1">N/A</span>
						</div>
					</div>
					<div class="ta-l">
						<div class="web-vitals__item--value" :class="getIndicatorSetup('cls').textColour">
							{{ checkWebVitalsScores("cls") ? website.data.webvitals.cls.toFixed(3) : "N/A" }}  
						</div>
						<div class="lh-1-5">
							<div class="web-vitals__item--text">CLS <small>Cumulative Layout Shift</small></div>
							<div class="web-vitals__item--sub-text">
								Visual stability, does the page move during/after load, find out more about CLS
								<a class="basic" href="https://web.dev/cls/#what-is-cls" target="_blank" rel="noopener nofollow">here</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="info-panel__index-score">
				<div class="info-panel__index-score__container">
					<div class="fw-b sz-16"><span class="info-panel__index-score__title">INDEX</span>Score</div>
					<div class="m-b-10">
						<span class="info-panel__index-score__brand sz-24 fw-b fc-white flc-white">
							<Svg :svg-type="svgType.GENERAL" svg-name="wviLogomark" svg-classes="sz-38" class="m-t-10 m-r-5"></Svg>

							<span v-if="website.data.index_score">{{ website.data.index_score.toFixed(1) }}</span>
						</span>
					</div>
					<div class="p-r-20 p-l-20 fw-l sz-11 tc-white-1">
						Overall score based on all metrics measured across the index.
					</div>
					<div class="m-t-20 sz-14">How do I improve?</div>
					<div class="info-panel__index-score__find-out sz-14 tc-ok fw-b hover-light" @click="findOutNow()">
						<span>Find out now</span>
						<span>
							<Svg :svg-type="svgType.GENERAL" svg-name="position_arrow" svg-classes="sz-14 flc-ok" class="m-t-5 m-r-5"></Svg>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="m-r-10 m-b-5 fl-r fw-l sz-11 tc-white-1 socials">
			<span class="m-r-5">
				Share these results:
			</span>
			<span class="socials">
				<Svg
					@click="share('twitter')"
					class="hover-light"
					:svg-type="svgType.GENERAL"
					svg-name="twitter"
					svg-classes="sz-16 m-r-5 m-l-5 flc-white"
				></Svg>
				<Svg @click="share('linkedin')" class="hover-light" :svg-type="svgType.GENERAL" svg-name="linkedIn" svg-classes="sz-16 m-r-5 flc-white"></Svg>
			</span>
		</div>
	</div>
</template>

<script>
// Components
import Svg from "../../../Utilities/Svg.vue";
import StatGraph from "./StatGraph.vue";

// Services, events etc.
import { SvgService } from "@/services/svg.service";
import { allDefault } from "@/services/wvi.service";
import { UtilsService } from "@/services/utils.service";
import { DataService } from "@/services/data.service";
import { modalEventsEmitter, events } from "@/events/events";

export default {
	components: {
		Svg,
		StatGraph,
	},
	props: ["website", "selectedIndustry"],
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		industry() {
			return this.selectedIndustry;
		},
	},
	methods: {
		closeInfo() {
			this.$emit("close-info");
		},
		getFavicon(websiteDomain) {
			return `https://s2.googleusercontent.com/s2/favicons?domain_url=${websiteDomain}`;
		},
		formatString(val, type) {
			const utilsSerivce = new UtilsService();
			return utilsSerivce.limitTo(val, type);
		},
		checkWebVitalsScores(metric) {
			const wvScores = this.website.data.webvitals;

			if ((wvScores[metric] === null) || (wvScores.lcp === 0 && wvScores.cls === 0 && wvScores.fid === 0)) {
				return false;
			} else {
				return true;
			}
		},
		getIndicatorSetup(metric) {
			const dataService = new DataService();
			const scores = dataService.getData("webvitalsScores");

			let improvement, poor, status, svgName, classes, value;

			value = this.website.data.webvitals[metric];

			classes = "sz-24";

			improvement = scores[metric].amber;
			poor = scores[metric].red;

			status = "error";
			svgName = "cross";

			if (value <= poor) {
				status = "issues";
				svgName = "exclaim";
			}
			if (value <= improvement) {
				status = "ok";
				svgName = "tick";
			}

			if (!this.checkWebVitalsScores(metric)) {
				status = "white-1";
			}

			return {
				svgClasses: `${classes} flc-${status}`,
				svgName,
				textColour: `tc-${status}`,
			};
		},
		share(social) {
			const utilsService = new UtilsService();
			const rank = this.selectedIndustry === "" ? this.website.rankings[allDefault] : this.website.rankings[this.selectedIndustry.token];
			const windowOptions = "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0";

			if (social === "twitter") {
				let shareURL = "http://twitter.com/share?";
				const params = {
					url: "webvitalsindex.com",
					text: `${this.website.label} are placed ${utilsService.ordinalSuffixOf(rank)} on the`,
					via: "WebVitalsIndex",
				};
				for (let prop in params) shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
				window.open(shareURL, "", windowOptions);
			}

			if (social === "linkedin") {
				let shareUrl = "https://www.linkedin.com/shareArticle?mini=true&url=";

				const params = {
					url: "webvitalsindex.com",
					title: `${this.website.label} are placed ${utilsService.ordinalSuffixOf(rank)} on the Web Vitals Index`,
					summary: `${this.website.label} - Web Vitals Index`,
					source: "Web Vitals Index",
				};

				shareUrl = `${shareUrl}${params.url}&title=${params.title}&summary${params.summary}&source=${params.source}`;
				window.open(shareUrl, "", windowOptions);
			}
		},
		findOutNow() {
			const type = "improve";
			const show = true;
			modalEventsEmitter.emit(events.MODAL_CHANGE, { type, show });

			if (window.innerWidth < 1100) {
				this.scrollToTop();
			}
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	},
};
</script>
