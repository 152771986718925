export class DataService {
	indexTableHeaders = [
		{
			label: "#",
			showOnSearch: false,
			tooltip: "Web Vitals Index Rank",
			columnClass: "rank",
		},
		{
			label: "rank-position",
			showOnSearch: false,
			tooltip: "Web Vitials Index Rank Weekly Change",
			columnClass: "rank-position-change",
		},
		{
			label: "Company",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "company",
		},
		{
			label: "",
			showOnSearch: true,
			columnClass: "industry",
		},
		{
			label: "Perf.",
			tooltip: "Performance /100",
			unit: "/100",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "lh-perf",
		},
		{
			label: "LCP",
			tooltip: "Largest Contentful Paint",
			unit: "ms",
			mini: true,
			alignLeft: true,
			showOnSearch: true,
			columnClass: "wv-lcp",
		},
		{
			label: "FID",
			tooltip: "First Input Delay",
			unit: "ms",
			mini: true,
			alignLeft: true,
			showOnSearch: true,
			columnClass: "wv-fid",
		},
		{
			label: "CLS",
			tooltip: "Cumulative Layout Shift",
			mini: true,
			alignLeft: true,
			showOnSearch: true,
			columnClass: "wv-cls",
		},
		{
			label: "Access.",
			tooltip: "Accessibility /100",
			unit: "/100",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "lh-access",
		},
		{
			label: "SEO",
			tooltip: "Search Engine Optimisation /100",
			unit: "/100",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "lh-seo",
		},
		{
			label: "BP",
			tooltip: "Best Practices /100",
			unit: "/100",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "lh-bp",
		},
		{
			label: "Uptime",
			tooltip: "Uptime %",
			unit: "%",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "uptime",
		},
		{
			pre: "INDEX",
			label: "Score",
			tooltip: "Web Vitals Index Score",
			alignLeft: true,
			showOnSearch: true,
			columnClass: "index-score",
		},
		{
			label: "",
			showOnSearch: true,
			columnClass: "row-open",
		},
	];

	lighthouseScores = {
		amber: 90,
		red: 50,
	};

	webvitalsScores = {
		lcp: {
			label: "Largest Contentful Paint (LCP)",
			short: "LCP",
			unit: "ms",
			value: "largest-contentful-paint",
			key: "largest_contentful_paint",
			trendvalue: "lcp",
			red: 4000,
			amber: 2500,
		},
		fid: {
			label: "First Input Delay (FID)",
			short: "FID",
			unit: "ms",
			value: "max-potential-fid",
			key: "max__fid",
			trendvalue: "fid",
			red: 300,
			amber: 100,
		},
		cls: {
			label: "Cumulative Layout Shift (CLS)",
			short: "CLS",
			unit: null,
			value: "cumulative-layout-shift",
			key: "cumulative_layout_shift",
			trendvalue: "cls",
			red: 0.25,
			amber: 0.1,
		},
	};

	uptimeScores = {
		amber: 98,
		red: 96,
	};

	getData(type) {
		switch (type) {
			case "indexTableHeaders":
				return this.indexTableHeaders;
			case "lighthouseScores":
				return this.lighthouseScores;
			case "webvitalsScores":
				return this.webvitalsScores;
			case "uptimeScores":
				return this.uptimeScores;
		}
	}
}
