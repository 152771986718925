<template>
	<Svg :svg-type="svgType.GENERAL" svg-name="position_arrow" svg-classes="sz-8"></Svg>
	<Svg :svg-type="svgType.GENERAL" svg-name="position_arrow" svg-classes="sz-8" class="bottom"></Svg>
</template>

<script>
//Components
import Svg from "../../../Utilities/Svg.vue";

//Services
import { SvgService } from "@/services/svg.service";

export default {
	components: {
		Svg,
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
	},
};
</script>

<style lang="scss">
.bottom {
	transform: scaleY(-1);
	margin-top: -7px;
}
</style>
