<template>
	<router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "./sass/master.scss";
</style>
