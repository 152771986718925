import { createRouter, createWebHistory } from 'vue-router'
import Main from '../components/Main/Main.vue';
import NotFound from '../components/NotFound/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Web Vitals Index',
    component: Main
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
