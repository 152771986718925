import { DataService } from "./data.service";

export class ColoursService {
	/**
	 * getColour
	 * @param {string} type - type of metric the colour is for.
	 * @param {string} subType - type of element that the colour is provided for, background is default
	 * @param {number} value - value of the metric
	 * @param {string} metric - metric name - mainly used to differentiate between web vitals - cls, fid and lcp.
	 * @return colour class.
	 */
	getColour(type, subType, value, metric) {
		let status, improvement, poor;

		const dataService = new DataService();

		const scores = dataService.getData(`${type}Scores`);

		switch (type) {
			case "lighthouse":
				improvement = scores.amber;
				poor = scores.red;

				status = "ok";
				if (value <= improvement) status = "issues";
				if (value <= poor) status = "error";

				if(value === 0) status = 'mid-4'

				break;
			case "webvitals":
				improvement = scores[metric].amber;
				poor = scores[metric].red;

				status = "error";

				if (value[metric] <= poor) status = "issues";
				if (value[metric] <= improvement) status = "ok";

				if(value[metric] === null || (value.lcp === 0 && value.cls === 0 && value.fid === 0)) status = 'mid-4'


				break;
			case "uptime":
				improvement = scores.amber;
				poor = scores.red;

				status = "ok";
				if (value <= improvement) status = "issues";
				if (value <= poor) status = "error";

				if(value < 90) status = 'mid-4'

				break;
		}

		

		// Return status color
		if (subType === "text") {
			return "tc-" + status;
		} else if (subType === "raw") {
			return status;
		} else if (subType === "svg") {
			return `flc-${status}`;
		} else if (subType === "svg-stroke") {
			return `stroke-${status}`;
		} else {
			return `bgc-${status} brc-${status}`;
		}
	}
}
