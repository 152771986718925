<template>
	<div class="header__shadow"></div>
	<div class="header">
		<div class="header__container-large header__container">
			<div class="header__industries m-a" v-if="!noData">
				<IndustrySelect :all-data="data"></IndustrySelect>
			</div>
			<div class="header__logo m-a">
				<Svg
					@click="showTopResults()"
					class="hover-cursor width-fit"
					:svg-type="svgType.GENERAL"
					svg-name="wviLogo"
					svg-classes="sz-223 flc-white"
				></Svg>
			</div>
			<div class="header__search m-a" v-if="!noData">
				<form @submit.prevent="search()">
					<input type="text" placeholder="Search" v-model="searchTerm" class="search br-5" />
				</form>
			</div>
		</div>

		<div class="header__container-mini header__container">
			<div class="header__container-mini__left m-r-10">
				<div class="header__logo m-a">
					<Svg
						@click="showTopResults()"
						class="hover-cursor width-fit"
						:svg-type="svgType.GENERAL"
						svg-name="wviLogomark"
						svg-classes="sz-90 flc-white"
					></Svg>
				</div>
			</div>
			<div v-if="!noData" class="header__container-mini__right" :class="{ 'header__container-mini__right--open': headerMini && industrySelectOpen }">
				<div class="header__search m-a m-b-5">
					<form @submit.prevent="search()">
						<input type="text" placeholder="Search" v-model="searchTerm" class="search br-5" />
					</form>
				</div>
				<div class="header__industries">
					<IndustrySelect :all-data="data"></IndustrySelect>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Components
import Svg from "../../Utilities/Svg.vue";
import IndustrySelect from "./IndustrySelect/IndustrySelect.vue";

// Services, Events etc.
import { allDefault } from "@/services/wvi.service";
import { searchEventsEmitter, events, industryEventsEmitter, noDataEventsEmitter } from "../../../events/events";
import { SvgService } from "@/services/svg.service";

export default {
	props: ['allData'],
	components: {
		Svg,
		IndustrySelect,
	},
	data() {
		return {
			selectedIndustry: allDefault,
			searchTerm: "",
			industrySelectOpen: false,
			headerMini: false,
			noData: false
		};
	},
	watch: {
		searchTerm: function(searchTerm) {
			setTimeout(() => {
				searchEventsEmitter.emit(events.SEARCH, this.searchTerm);

				if (searchTerm === "") {
					searchEventsEmitter.emit(events.SEARCH_EMPTY);
				}
			}, 500);
		},
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		data: function(){
			return this.allData;
		}
	},
	methods: {
		search() {
			if (this.searchTerm !== "") {
				searchEventsEmitter.emit(events.SEARCH, this.searchTerm);
			}
		},
		showTopResults() {
			industryEventsEmitter.emit(events.RESET_DEFAULT_INDUSTRY);
		},
		watchWindowResize() {
			window.innerWidth < 960 ? (this.headerMini = true) : (this.headerMini = false);
		},
	},
	mounted() {
		searchEventsEmitter.on(events.CLEAR_SEARCH, () => {
			this.searchTerm = "";
		});

		industryEventsEmitter.on(events.INDUSTRY, (payload) => {
			this.searchTerm = "";
		});

		industryEventsEmitter.on(events.TOGGLE_SHOW_INDUSTRIES, (payload) => {
			this.industrySelectOpen = payload;
		});

		noDataEventsEmitter.on(events.NO_DATA_AVAILABLE, (payload) => {
			this.noData = payload;
		});
	},
	created() {
		this.watchWindowResize();
		window.addEventListener("resize", this.watchWindowResize);

		
	},
};
</script>

<style lang="scss"></style>
