<template>
	<div @click="toggleShowIndustries()" :class="{ 'industry-select--open': showIndustries }" class="industry-select">
		<Svg :svg-type="svgType.INDUSTRY" :svg-name="svgName(this.selectedIndustry)" svg-classes="sz-14 m-t-2 flc-white-1" :key="svgKey"></Svg>
		<span class="industry-select__label">
			{{ formatIndustrySelectLabel(this.selectedIndustry.label, true) }}
		</span>

		<Svg :svg-type="svgType.GENERAL" svg-name="toggle_arrow" svg-classes="sz-14 m-t-2 flc-white-1"></Svg>
	</div>

	<div v-if="showIndustries" class="industry-select__dropdown" v-click-away="clickOutside">
		<div class="industry-select__item" v-for="industry in data.industries" :key="industry.token" @click="selectIndustry(industry)">
			<Svg :svg-type="svgType.INDUSTRY" :svg-name="svgName(industry)" svg-classes="sz-14 m-t-5 flc-white-1"></Svg>
			<span class="industry-select__item--label">
				{{ formatIndustrySelectLabel(industry.label, false) }}
			</span>
		</div>
	</div>
</template>

<script>
// Components
import Svg from "../../../Utilities/Svg.vue";

// Services
import { SvgService } from "@/services/svg.service";
import { allDefault } from "@/services/wvi.service";
import { industryEventsEmitter, searchEventsEmitter, events } from "@/events/events";

export default {
	props: ['allData'],
	components: {
		Svg,
	},
	data() {
		return {
			showIndustries: false,
			selectedIndustry: {
				label: "All",
				token: allDefault,
			},
			svgKey: 0,
		};
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		data: function(){
			return this.allData;
		}
	},
	methods: {
		toggleShowIndustries() {
			this.showIndustries = !this.showIndustries;
			industryEventsEmitter.emit(events.TOGGLE_SHOW_INDUSTRIES, this.showIndustries);
		},
		selectIndustry(industry) {
			this.selectedIndustry = industry;
			this.showIndustries = false;
			this.svgKey++;
			industryEventsEmitter.emit(events.INDUSTRY, this.selectedIndustry);
			industryEventsEmitter.emit(events.TOGGLE_SHOW_INDUSTRIES, this.showIndustries);
		},
		svgName(industry) {
			const indexOfTokenUuid = industry.token.lastIndexOf("_");
			const industrySvgName = industry.token.slice(0, indexOfTokenUuid);
			return industrySvgName;
		},
		formatIndustrySelectLabel(label, shorten) {
			const selectLabel = label === "All" ? "Top 25 (All)" : label;
			const maxLength = 22;

			return shorten ? (selectLabel.length > maxLength ? selectLabel.substr(0, maxLength - 1) + "..." : selectLabel) : selectLabel;
		},
		clickOutside() {
			if (this.showIndustries) {
				this.showIndustries = false;
				industryEventsEmitter.emit(events.TOGGLE_SHOW_INDUSTRIES, this.showIndustries);
			}
		},
	},
	mounted() {
		searchEventsEmitter.on(events.CLEAR_SEARCH, () => {
			this.selectedIndustry = this.data.industries[allDefault];
			this.svgKey++;
		});

		industryEventsEmitter.on(events.RESET_DEFAULT_INDUSTRY, () => {
			this.selectIndustry({ label: "All", token: allDefault });
		});
	},
};
</script>

<style></style>
