<template>
	<div class="overlay" @click="closeModal()"></div>
	<div class="modal">
		<div class="modal__title-bar">
			<div class="modal__title">
				<Svg :svg-type="svgType.GENERAL" svg-name="wviLogomark" svg-classes="sz-28 flc-white" class="m-t-5 m-r-10"></Svg>
				<h1>{{ modalTitle }}</h1>
			</div>
			<div class="modal__close" @click="closeModal()">
				<Svg :svg-type="svgType.GENERAL" svg-name="cross" svg-classes="sz-16 flc-white"></Svg>
			</div>
		</div>

		<div class="modal__body m-t-20">
			<div class="modal__copy">
				<p class="m-b-20">{{ modalCopy }}</p>
				<small class="tc-white-1">{{ modalSmallPrint }}</small>
			</div>

			<div class="modal__content">
				<div id="hubspot-form"></div>
			</div>
		</div>
	</div>
</template>

<script>
// Components
import Svg from "../Svg.vue";

// Services etc
import { SvgService } from "@/services/svg.service";
import { modalEventsEmitter, events } from "@/events/events";

export default {
	components: {
		Svg
	},
	props: ["modalType"],
	data() {
		return {
			showModal: false,
			modalTitle: "Sign Up Now",
			modalCopy: "Fill in the form",
			modalSmallPrint: "Terms & Conditions Apply",
		};
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
	},
	methods: {
		closeModal() {
			modalEventsEmitter.emit(events.MODAL_CHANGE, { show: false });
		},
		setupHubSpotForms() {
			// Claim Your Place
			const script = document.createElement("script");
			script.src = "https://js.hsforms.net/forms/v2.js";

			document.body.appendChild(script);

			if (this.modalType === "claim") {
				script.addEventListener("load", () => {
					if (window.hbspt) {
						window.hbspt.forms.create({
							region: "na1",
							portalId: "8633855",
							formId: "0f1e8695-7d08-4eb1-bd70-88c291b33a68",
							target: "#hubspot-form",
							cssRequired: ".submitted-message { color: #ffffff !important; }",
						});
					}
				});
			}

			// Improve your scores
			if (this.modalType === "improve") {
				script.addEventListener("load", () => {
					if (window.hbspt) {
						window.hbspt.forms.create({
							region: "na1",
							portalId: "8633855",
							formId: "4f1f63ed-df1a-4d1a-b1f4-392fec2d0a00",
							target: "#hubspot-form",
							cssRequired: ".submitted-message { color: #ffffff !important; }",
						});
					}
				});
			}
		},
	},
	mounted() {
		// Switch on the modal type to determine the content.
		switch (this.modalType) {
			case "improve":
				this.modalTitle = "Improve Your Scores!";
				this.modalCopy =
					"Our partner RapidSpike is on hand to give you insight into your position on the leaderboard, and help you move up in the ranks!";
				this.modalSmallPrint = "";
				break;
			case "claim":
				this.modalTitle = "Claim Your Place!";
				this.modalCopy =
					"Join the Web Vitals Index today to see where your website ranks for Performance, Core Web Vitals and Uptime in relation to the biggest websites in the UK.";
				this.modalSmallPrint =
					"To qualify for a place on the index your organisation must have an eCommerce website generating at least £10 million annual revenue.";
				break;
		}

		this.setupHubSpotForms();
	},
	beforeUnmount() {
		const hubspotForm = document.getElementById("hubspot-form");
		hubspotForm.innerHTML = "";
	},
};
</script>

<style scoped lang="scss">
.submitted-message {
	color: #fff !important;
}
</style>
