<template>
	<div class="donut-item tt">
		<svg viewBox="0 0 40 40" class="donut" :class="{ 'sz-18': mini, 'sz-52': !mini }">
			<circle class="donut__hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
			<circle class="donut__ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
			<circle
				class="donut__segment"
				:class="getColour(type, 'svg-stroke', value)"
				cx="20"
				cy="20"
				r="15.91549430918954"
				fill="transparent"
				:stroke-width="getStrokeWidth()"
				:stroke-dasharray="getDashArrayValue()"
				stroke-dashoffset="25"
			></circle>
			<g :class="getColour(type, 'svg', value)" v-if="!mini">
				<text y="50%" transform="translate(0, 3)">
					<tspan x="50%" text-anchor="middle" class="donut__percent">{{ value === 0 && type === "lighthouse" ? "N/A" : formatValue(type) }}</tspan>
				</text>
			</g>
		</svg>
		<span class="stat-value" v-if="mini" :class="getColour(type, 'text', value)">{{ formatValue(type) }}%</span>

		<span class="tt-hover" v-if="type === 'uptime' && value === 0">Uptime not avaiable</span>
		<span class="tt-hover" v-if="type === 'uptime' && value < 90 && value > 0">Uptime needs improvement</span>
	</div>
</template>

<script>
import { ColoursService } from "@/services/colours.service";
import { UtilsService } from "@/services/utils.service";

export default {
	props: ["value", "type", "mini"],
	data() {
		return {};
	},
	methods: {
		getDashArrayValue() {
			let remainder = 0;
			if (this.type === "uptime") {
				remainder = 100 - this.value;
				return `${this.value} ${remainder}`;
			}

			if (this.type === "lighthouse") {
				const utilsService = new UtilsService();
				remainder = 100 - utilsService.formatPercentage(this.value);
				return `${utilsService.formatPercentage(this.value)} ${remainder}`;
			}
		},
		getStrokeWidth() {
			return this.mini ? "25%" : "15%";
		},
		getColour(type, subType, value, metric) {
			const coloursService = new ColoursService();
			const val = type === "lighthouse" ? this.formatValue(value) : value;
			return coloursService.getColour(type, subType, val, metric);
		},
		formatValue() {
			if (this.type === "uptime") {
				return this.value === 100 || this.value === 0 ? this.value : this.value.toFixed(2);
			}

			if (this.type === "lighthouse") {
				const utilsService = new UtilsService();
				return utilsService.formatPercentage(this.value);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
