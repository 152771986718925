<template>
	<div v-html="svg"></div>
</template>

<script>
import { SvgService } from "../../services/svg.service";

export default {
	props: ["svgType", "svgName", "svgClasses", "svgColor"],
	data() {
		return {
			svg: "",
		};
	},
	methods: {
		getSvg() {
			const svgService = new SvgService();
			this.svg = svgService.getSvg(this.svgType, this.svgName, this.svgClasses);
		},
	},
	mounted() {
		this.getSvg();
	},
};
</script>

<style></style>
