// Packages
import axios from "axios";

// Services, Events etc
import { UtilsService } from "./utils.service";
import { noDataEventsEmitter, loadingEventsEmitter, events } from "@/events/events";

export const allDefault = "all_6923b973ad";
export class WebVitalsIndexService {
	dataUrl = "https://reports.webvitalsindex.com";
	currentWeekFile = "current.json";
	previousWeekFile = "previous.json";

	/**
	 * getData
	 * @param selectedIndustry represents the selected industry in the drop down, in order to sort by rank and only
	 * return websites for the selected industry..
	 * @returns dataObj {sortedCurrentWeekWebsites, previousWeekWebsites, industries, currentWeekGeneratedData, previousWeekGeneratedData}
	 */
	async getData(selectedIndustry) {
		const currentWeekUrl = `${this.dataUrl}/output/${this.currentWeekFile}`;
		const previousWeekUrl = `${this.dataUrl}/output/${this.previousWeekFile}`;

		loadingEventsEmitter.emit(events.LOADING, true);

		let noData = false;

		let allData = await axios
			.get(currentWeekUrl)
			.then((currentWeekReponse) => {
				return currentWeekReponse;
			})
			.then(async (currentWeekData) => {
				const previousWeekData = await axios.get(previousWeekUrl);

				return {
					currentWeekData: currentWeekData.data,
					previousWeekData: previousWeekData.data,
				};
			})
			.catch((err) => {
				return { ...err };
			});

		if (allData.response && allData.response.status === 403) {
			const backupAllData = await axios
				.get(previousWeekUrl)
				.then((previousWeekResponse) => {
					return {
						currentWeekData: previousWeekResponse.data,
						previousWeekData: previousWeekResponse.data,
					};
				})
				.catch((err) => {
					return { ...err };
				});

			if (backupAllData.response && backupAllData.response.status === 403) {
				noData = true;
				noDataEventsEmitter.emit(events.NO_DATA_AVAILABLE, true);
			} else {
				allData = backupAllData;
			}
		}

		if (!noData) {
			// Get industry token or set to allDefault if the industry hasn't changed.
			let industryToken = "";
			if (selectedIndustry === "" || selectedIndustry === undefined) {
				industryToken = allDefault;
			} else {
				industryToken = selectedIndustry.token ? selectedIndustry.token : selectedIndustry;
			}

			const sortedCurrentWeekWebsites = this.sortWebsitesBySelectedIndustry(industryToken, allData.currentWeekData.websites);
			const currentWeekWebsites = this.getWebsiteRankPositionChange(industryToken, sortedCurrentWeekWebsites, allData.previousWeekData.websites);

			const dataObj = {
				currentWeekWebsites,
				previousWeekWebsites: allData.previousWeekData.websites,
				allCurrentWeekWebsites: allData.currentWeekData.websites,
				industries: allData.currentWeekData.industries,
				currentWeekGeneratedDate: allData.currentWeekData.created_timestamp,
				previousWeekGeneratedDate: allData.previousWeekData.created_timestamp,
				noData,
			};

			loadingEventsEmitter.emit(events.LOADING, false);

			return dataObj;
		} else {

			loadingEventsEmitter.emit(events.LOADING, false);
			
			const dataObj = {
				currentWeekWebsites: {},
				previousWeekWebsites: {},
				industries: {},
				currentWeekGeneratedDate: {},
				previousWeekGeneratedDate: {},
				noData,
			};

			return dataObj;
		}
	}

	/**
	 * sortWebsitesBySelectedIndustry
	 * @param {*} selectedIndsutry
	 * @param {*} currentWesbites
	 * @returns object of sorted websites by rank.
	 */
	sortWebsitesBySelectedIndustry(industryToken, currentWesbites) {
		// selectedIndsutry = "cosmetics_healthcare_3e4e9e0070";

		if (currentWesbites) {
			// Convert the websites object into an array to filter and sort.
			const websitesArr = Object.entries(currentWesbites);

			// Filter websites to only return websites for the selected industry
			const filteredWebsites = websitesArr.filter((website) => {
				const websiteIndustries = website[1].industries;

				let containsIndustry = industryToken in websiteIndustries;

				if (containsIndustry) {
					return website;
				}
			});

			// // Sort websites by rank based on the industry.
			const sortedWebsites = filteredWebsites.sort((a, b) => {
				const aVal = a[1].rankings[industryToken];
				const bVal = b[1].rankings[industryToken];
				return aVal - bVal;
			});

			// Convert sorted websites array back to an object, limit websites to top 25 and return.
			return Object.fromEntries(sortedWebsites.slice(0, 25));
		}
	}

	/**
	 * getWebsiteRankPositionChange
	 * @param {string} industryToken
	 * @param {*} currentWeekWebsites
	 * @param {*} previousWeekWebsites
	 * @return {rankDifferent, isNewWebsite}
	 */
	getWebsiteRankPositionChange(industryToken, currentWeekWebsites, previousWeekWebsites) {
		let currentWeekArr;
		if (currentWeekWebsites) {
			currentWeekArr = Object.entries(currentWeekWebsites);

			// Loop through all of the current week websites
			currentWeekArr.forEach((website) => {
				const currentWeekWebsiteRankings = website[1].rankings;
				let previousWeekWebsiteRankings;

				// get the previous weeks website rankings
				if (previousWeekWebsites[website[0]]) {
					previousWeekWebsiteRankings = previousWeekWebsites[website[0]].rankings;
				}

				// get rankings for current and previous for the idustry.
				const currentWeekRank = currentWeekWebsiteRankings[industryToken];
				const previousWeekRank = previousWeekWebsiteRankings ? previousWeekWebsiteRankings[industryToken] : null;

				let rankDifference;
				let isNewWebsite = false;
				let bigMover = false;

				// get rank difference between previous and current ranks.
				if (currentWeekRank !== null && previousWeekRank !== null) {
					rankDifference = previousWeekRank - currentWeekRank;
				}

				// If previous week is null then the website is new.
				if (previousWeekRank === null) {
					isNewWebsite = true;
				}

				//Get any big movers
				if (previousWeekRank > 25 && currentWeekRank <= 25) {
					bigMover = true;
				}

				// return website with new rankDifference property.
				website[1].rankDifference = {
					rankDifference,
					isNewWebsite,
					bigMover,
				};
			});

			return Object.fromEntries(currentWeekArr);
		}
	}

	/**
	 * filterWebsitesBySearch
	 * @param {string} searchTerm
	 */
	filterWebsitesBysearch(searchTerm, websites) {
		if (searchTerm === "") return websites;

		const websitesArry = Object.entries(websites);
		const searchTermLower = searchTerm.toLowerCase();

		const filteredWebsites = websitesArry.filter((website) => {
			const websiteData = website[1];

			const websiteLabel = websiteData.label.toLowerCase();
			const websiteDomain = websiteData.domain.toLowerCase();

			if (websiteLabel.includes(searchTermLower) || websiteDomain.includes(searchTermLower)) {
				return website;
			}
		});

		const searchResults = Object.fromEntries(filteredWebsites);

		return searchResults;
	}

	/**
	 * getRanking
	 * @param {*} websiteRankings
	 * @param {*} selectedIndustry
	 * @returns the ranking of the website for the given industry.
	 */
	getRanking(websiteRankings, selectedIndustry) {
		if (!selectedIndustry) selectedIndustry = allDefault;
		return websiteRankings[selectedIndustry];
	}

	/**
	 * getRankPositionChange
	 * @param {string} websiteUuid
	 * @param {*} industryToken
	 * @returns the rank change for the website.
	 */
	getRankPositionChange(websiteUuid, industryToken) {
		if (!industryToken) industryToken = allDefault;

		const utilsService = new UtilsService();

		const year = new Date().getFullYear();
		const currentWeekNumber = utilsService.getWeekNumber();
		const previousWeekNumber = utilsService.getWeekNumber() - 1;

		const currentWeekWebsites = JSON.parse(localStorage.getItem(`${year}-${currentWeekNumber}-web-vitals-index-websites`));
		const previousWeekWebsites = JSON.parse(localStorage.getItem(`${year}-${previousWeekNumber}-web-vitals-index-websites`));

		let currentWebsiteRank = 0;

		if (currentWeekWebsites[websiteUuid]) {
			currentWebsiteRank = currentWeekWebsites[websiteUuid].rankings[industryToken];
		}

		let previousWebsiteRank = 0;
		let isNewWebsite = false;
		let bigMover = false;
		let rankDifference = 0;

		if (previousWeekWebsites !== null && previousWeekWebsites[websiteUuid]) {
			previousWebsiteRank = previousWeekWebsites[websiteUuid].rankings[industryToken];
			rankDifference = previousWebsiteRank - currentWebsiteRank;
		} else {
			isNewWebsite = true;
		}

		if (previousWebsiteRank > 25 && currentWebsiteRank <= 25) {
			bigMover = true;
		}

		// Catches cases where the website was added and the industries and rankings have been missed, and treats the
		// website as a new site to prevent the position change calculation trying to do 'undefined - 20' for example and returning 'Nan'
		if (previousWebsiteRank === undefined) {
			isNewWebsite = true;
		}

		return { rankDifference, isNewWebsite, bigMover };
	}

	
}
