<template>
	<div class="loader__main">
		<div class="loader__container">
			<svg class="loader__circle" viewBox="25 25 50 50">
				<circle class="loader__path" cx="50" cy="50" r="20" fill="none" stroke-width="2" />
			</svg>
		</div>
	</div>
</template>

<script>
export default {};
</script>
