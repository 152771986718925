<template>
	<div class="web-vitals-index">
		<div id="table-header" class="table__header table__row p-tb-8" :class="{ 'table__row--search': searchActive, 'table__header--sticky' : headerSticky }">
			<!-- Table Headers -->
			<div
				v-for="header in tableHeaders"
				:key="header.label"
				:class="[
					{
						'table__header--left': header.label === 'Company',
						'ta-l': header.alignLeft,
						'p-l-10': header.label === 'Company',
					},
					header.columnClass,
				]"
				class="tt p-l-5"
			>
				<span v-if="header.label !== 'rank-position'">
					<span v-if="header.pre" class="table__header--pre">
						{{ header.pre }}
					</span>
					<span class="table__header--label fw-b" :class="{ 'table__header--label-mini': header.mini }">
						{{ header.label }}
					</span>
					<span class="table__header--unit" v-if="header.unit">
						{{ header.unit }}
					</span>
				</span>
				<span v-if="header.label === 'rank-position'">
					<RankPositionHeader></RankPositionHeader>
				</span>
				<span class="tt-hover" v-if="header.tooltip">{{ header.tooltip }}</span>
			</div>
		</div>

		<div class="table__no-data" v-if="this.allData.noData">
			<div class="table__no-data--title">
				<Svg :svg-type="svgType.GENERAL" svg-name="exclaim" svg-classes="sz-22 flc-white"></Svg>
				<h1>No Web Vitals Index Data Available</h1>
			</div>
			<div>
				<h4>There seems to be a issue with the Web Vitals Index, please refresh or try again later.</h4>
			</div>
		</div>

		<div v-show="!loading">
			<div class="table__body m-t-10" :class="{'m-t-60' : headerSticky}" v-if="!this.allData.noData">
				<div
					class="table__row"
					:class="{ 'table__row--search': searchActive }"
					v-for="website in websites"
					:key="website.uuid"
					@mouseenter="website.showRowStats = true"
					@mouseleave="website.showRowStats = false"
				>
					<!-- Rank (Trophy for top 3) -->
					<div class="rank fade-in p-tb-8 p-lr-10" v-if="!searchActive">
						<span class="fw-b sz-14" v-if="!isTopRank(getRanking(website.rankings))">
							{{ getRanking(website.rankings) }}
						</span>
						<RankTrophy :key="rankKey" :rank="getRanking(website.rankings)" v-if="isTopRank(getRanking(website.rankings))"></RankTrophy>
					</div>

					<!-- Rank position change -->
					<div class="rank-position-change fade-in p-tb-8 p-lr-10" v-if="!searchActive">
						<RankPositionChange :key="rankKey" :website="website" :industry="selectedIndustry"></RankPositionChange>
					</div>

					<!-- Company Label & Domain -->
					<div class="company fade-in p-tb-8 p-lr-10 ">
						<div class="company__row" :class="{ 'company__row--searched': searchActive }">
							<div>
								<a :href="website.domain" target="_blank" rel="noopener nofollow" class="m-r-10 company__favicon">
									<img height="20" width="20" class="m-r-10 company__favicon--img" :src="getFavicon(website.domain)" :alt="website.label" />
								</a>

								<span class="company__label m-r-15 tt">
									<h3
										@click="
											closeAllInfo();
											website.showInfo = !website.showInfo;
										"
										class="hover-cursor"
									>
										<span class="company__label" v-if="companyMiniLong" v-html="formatString(website.label, 'label', 15, 15)"></span>

										<span class="company__label--mini" v-if="companyMini" v-html="formatString(website.label, 'label', 8, 10)"></span>
										<span class="company__label--medium" v-if="companyMedium" v-html="formatString(website.label, 'label', 15, 15)"></span>
										<span class="company__label--full" v-if="companyFull">{{ website.label }}</span>
									</h3>
									<span class="tt-hover" v-if="website.label.length > 10 && (companyMiniLong || companyMedium || companyMini)">{{
										website.label
									}}</span>
								</span>
							</div>
						</div>
					</div>

					<!-- Industry Icon -->
					<div class="industry fade-in p-tb-8 p-lr-10 tt">
						<Svg :svg-type="svgType.INDUSTRY" :svg-name="getSvgName(website.industries)" svg-classes="sz-18 flc-mid-4"></Svg>
						<span class="tt-hover">{{ getWebsiteIndustry(website.industries).label }}</span>
					</div>

					<!-- Lighthouse Performnce -->
					<div class="lh-perf fade-in p-tb-8 p-lr-10">
						<span
							v-if="!checkDataNullValues('lh', website.data.lighthouse.performance)"
							class="data p-l-4 sz-14 fw-b hover-cursor"
							:class="{ 'fw-l': checkDataNullValues('lh', website.data.lighthouse.performance) }"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<span class="data__indicator" :class="getColour('lighthouse', 'bgc', formatPercentage(website.data.lighthouse.performance))"> </span>
							<span
								v-if="showStats || website.showRowStats"
								:class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.performance))"
							>
								<span>{{ formatPercentage(website.data.lighthouse.performance) }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="checkDataNullValues('lh', website.data.lighthouse.performance)">
							<span :class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.performance))">N/A</span>
							<span class="tt-hover">LH Performance N/A</span>
						</span>
					</div>

					<!-- Web Vitals LCP -->
					<div class="wv-lcp fade-in p-tb-8 p-lr-10 ">
						<span
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
							v-if="checkDataNullValues('wv', website.data.webvitals.lcp, website.data.webvitals)"
							class="data p-l-4 sz-11 fw-l hover-cursor"
						>
							<span class="data__indicator data__indicator--mini" :class="getColour('webvitals', 'bgc', website.data.webvitals, 'lcp')"></span>
							<span v-if="showStats || website.showRowStats" :class="getColour('webvitals', 'text', website.data.webvitals, 'lcp')">
								<span v-if="website.data.webvitals.lcp">{{ website.data.webvitals.lcp.toFixed() }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="!checkDataNullValues('wv', website.data.webvitals.lcp, website.data.webvitals)">
							<span :class="getColour('webvitals', 'text', website.data.webvitals, 'lcp')">N/A</span>
							<span class="tt-hover">LCP N/A</span>
						</span>
					</div>

					<!-- Web Vitals FID -->
					<div class="wv-fid fade-in p-tb-8 p-lr-10 ">
						<span
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
							v-if="checkDataNullValues('wv', website.data.webvitals.fid, website.data.webvitals)"
							class="data p-l-4 sz-11 fw-l hover-cursor"
						>
							<span class="data__indicator data__indicator--mini" :class="getColour('webvitals', 'bgc', website.data.webvitals, 'fid')"></span>
							<span v-if="showStats || website.showRowStats" :class="getColour('webvitals', 'text', website.data.webvitals, 'fid')">
								<span v-if="website.data.webvitals.fid">{{ website.data.webvitals.fid.toFixed() }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="!checkDataNullValues('wv', website.data.webvitals.fid, website.data.webvitals)">
							<span :class="getColour('webvitals', 'text', website.data.webvitals, 'fid')">N/A</span>
							<span class="tt-hover">FID N/A</span>
						</span>
					</div>

					<!-- Web Vitals CLS -->
					<div class="wv-cls fade-in p-tb-8 p-lr-10 ">
						<span
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
							v-if="checkDataNullValues('wv', website.data.webvitals.cls, website.data.webvitals)"
							class="data p-l-4 sz-11 fw-l hover-cursor"
						>
							<span class="data__indicator data__indicator--mini" :class="getColour('webvitals', 'bgc', website.data.webvitals, 'cls')"></span>
							<span v-if="showStats || website.showRowStats" :class="getColour('webvitals', 'text', website.data.webvitals, 'cls')">
								<span v-if="website.data.webvitals.cls">{{ website.data.webvitals.cls.toFixed(5) }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="!checkDataNullValues('wv', website.data.webvitals.cls, website.data.webvitals)">
							<span :class="getColour('webvitals', 'text', website.data.webvitals, 'cls')">N/A</span>
							<span class="tt-hover">CLS N/A</span>
						</span>
					</div>

					<!-- Lighthouse Accessability -->
					<div class="lh-access fade-in p-tb-8 p-lr-10 ">
						<span
							v-if="!checkDataNullValues('lh', website.data.lighthouse.accessibility)"
							class="data p-l-4 sz-14 fw-b hover-cursor"
							:class="{ 'fw-l': checkDataNullValues('lh', website.data.lighthouse.accessibility) }"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<span class="data__indicator" :class="getColour('lighthouse', 'bgc', formatPercentage(website.data.lighthouse.accessibility))"></span>
							<span
								v-if="showStats || website.showRowStats"
								:class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.accessibility))"
							>
								<span>{{ formatPercentage(website.data.lighthouse.accessibility) }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="checkDataNullValues('lh', website.data.lighthouse.accessibility)">
							<span :class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.seo))">N/A</span>
							<span class="tt-hover">LH Accessibility N/A</span>
						</span>
					</div>

					<!-- Lighthouse SEO -->
					<div class="lh-seo fade-in p-tb-8 p-lr-10">
						<span
							v-if="!checkDataNullValues('lh', website.data.lighthouse.seo)"
							class="data p-l-4 sz-14 fw-b hover-cursor"
							:class="{ 'fw-l': checkDataNullValues('lh', website.data.lighthouse.seo) }"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<span class="data__indicator" :class="getColour('lighthouse', 'bgc', formatPercentage(website.data.lighthouse.seo))"></span>
							<span v-if="showStats || website.showRowStats" :class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.seo))">
								<span>{{ formatPercentage(website.data.lighthouse.seo) }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="checkDataNullValues('lh', website.data.lighthouse.seo)">
							<span :class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.seo))">N/A</span>
							<span class="tt-hover">LH SEO N/A</span>
						</span>
					</div>

					<!-- Lighthouse Best Practices -->
					<div class="lh-bp fade-in p-tb-8 p-lr-10 ">
						<span
							v-if="!checkDataNullValues('lh', website.data.lighthouse.best_practices)"
							class="data p-l-4 sz-14 fw-b hover-cursor"
							:class="{ 'fw-l': checkDataNullValues('lh', website.data.lighthouse.best_practices) }"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<span class="data__indicator" :class="getColour('lighthouse', 'bgc', formatPercentage(website.data.lighthouse.best_practices))"></span>
							<span
								v-if="showStats || website.showRowStats"
								:class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.best_practices))"
							>
								<span>{{ formatPercentage(website.data.lighthouse.best_practices) }}</span>
							</span>
						</span>

						<span class="data p-l-4 sz-14 fw-l tt" v-if="checkDataNullValues('lh', website.data.lighthouse.best_practices)">
							<span :class="getColour('lighthouse', 'text', formatPercentage(website.data.lighthouse.best_practices))">N/A</span>
							<span class="tt-hover">LH Best Practices N/A</span>
						</span>
					</div>

					<!-- Uptime -->
					<div class="uptime fade-in p-tb-8 p-lr-10 ">
						<span
							class="data p-l-4 sz-14 fw-l hover-cursor"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<StatGraph :value="website.data.uptime" type="uptime" :mini="true"></StatGraph>
						</span>
					</div>

					<!-- Index Score -->
					<div class="index-score fade-in p-tb-8 p-lr-10 tt">
						<span
							class="data sz-14 fw-b fc-white flc-white hover-cursor"
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
						>
							<Svg :svg-type="svgType.GENERAL" svg-name="wviLogomark" svg-classes="sz-20" class="m-t-5 m-r-5"></Svg>
							<span v-if="website.data.index_score">{{ website.data.index_score.toFixed(1) }}</span>
							<span class="tt-hover" v-if="website.data.index_score"> Web Vitals Index score - {{ website.data.index_score.toFixed(1) }} </span>
						</span>
					</div>

					<!-- Row Opener -->
					<div class="row-open fade-in p-tb-8 p-lr-10 ">
						<Svg
							@click="
								closeAllInfo();
								website.showInfo = !website.showInfo;
							"
							class="hover-fill-ok"
							:svg-type="svgType.GENERAL"
							svg-name="toggle_arrow"
							svg-classes="sz-18 flc-mid-4"
						></Svg>
					</div>
					<InfoPanel
						@close-info="
							website.showInfo = !website.showInfo;
							website.showRowStats = false;
						"
						:website="website"
						:selected-industry="selectedIndustry"
						v-if="website.showInfo"
					>
						<template v-slot:trophy>
							<span class="fw-b sz-14" v-if="!isTopRank(getRanking(website.rankings))">
								{{ getRanking(website.rankings) }}
							</span>
							<RankTrophy :key="rankKey" :rank="getRanking(website.rankings)" v-if="isTopRank(getRanking(website.rankings))"></RankTrophy>
						</template>
						<template v-slot:position>
							<RankPositionChange :key="rankKey" :website="website" :industry="selectedIndustry"></RankPositionChange>
						</template>
						<template v-slot:industry>
							<div class="info-panel__industry">
								<Svg :svg-type="svgType.INDUSTRY" :svg-name="getSvgName(website.industries)" svg-classes="sz-14 flc-white m-r-5 m-t-5"></Svg>
								{{ getWebsiteIndustry(website.industries).label }}
							</div>
						</template>
					</InfoPanel>
				</div>
			</div>
		</div>

		<div class="table__footer"></div>
	</div>
</template>

<script>
// Components
import Svg from "../../Utilities/Svg.vue";
import RankTrophy from "./Rank/RankTrophy.vue";
import RankPositionHeader from "./Rank/RankPositionHeader.vue";
import RankPositionChange from "./Rank/RankPositionChange.vue";
import StatGraph from "./Visuals/StatGraph.vue";
import InfoPanel from "./Visuals/InfoPanel.vue";

// Services
import { DataService } from "@/services/data.service";
import { WebVitalsIndexService, allDefault } from "@/services/wvi.service";
import { SvgService } from "@/services/svg.service";
import { UtilsService } from "@/services/utils.service";
import { ColoursService } from "@/services/colours.service";
import { industryEventsEmitter, loadingEventsEmitter, statsEventsEmitter, searchEventsEmitter, events } from "@/events/events";

export default {
	components: {
		Svg,
		RankTrophy,
		RankPositionHeader,
		RankPositionChange,
		StatGraph,
		InfoPanel,
	},
	props: ["allData"],
	data() {
		return {
			selectedIndustry: "",
			svgName: "",
			rankKey: 0,
			tableKey: 0,
			showStats: false,
			searchActive: false,
			searchTerm: "",
			indexTableKey: 0,
			dataFailed: false,
			companyMini: false,
			comapnyMiniLong: false,
			companyMedium: false,
			companyFull: false,
			loading: false,
			headerSticky: false,
		};
	},
	computed: {
		tableHeaders() {
			const dataService = new DataService();

			let tableHeaders = dataService.getData("indexTableHeaders");
			const searchActive = this.searchActive;

			if (searchActive) {
				tableHeaders = tableHeaders.filter((header) => {
					if (header.showOnSearch) return header;
				});
				return tableHeaders;
			} else {
				return tableHeaders;
			}
		},
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		data: function() {
			return this.allData;
		},
		websites: function() {
			const wviService = new WebVitalsIndexService();

			if (this.searchActive) {
				return wviService.filterWebsitesBysearch(this.searchTerm, this.allData.allCurrentWeekWebsites);
			}

			return this.allData.currentWeekWebsites;
		},
	},
	methods: {
		getRanking(websiteRankings) {
			const wviService = new WebVitalsIndexService();

			let industryToken = "";
			if (this.selectedIndustry === "") {
				industryToken = allDefault;
			} else {
				industryToken = this.selectedIndustry.token;
			}

			return wviService.getRanking(websiteRankings, industryToken);
		},
		isTopRank(rank) {
			return (rank === 1) | (rank === 2) | (rank === 3);
		},
		getFavicon(websiteDomain) {
			return `https://s2.googleusercontent.com/s2/favicons?domain_url=${websiteDomain}`;
		},
		getSvgName(websiteIndustries) {
			const svgService = new SvgService();

			let websiteIndustry = {};
			for (const industry in websiteIndustries) {
				if (websiteIndustries[industry].token !== allDefault) {
					websiteIndustry = websiteIndustries[industry];
				}
			}
			return svgService.getSvgName(websiteIndustry);
		},
		getWebsiteIndustry(websiteIndustries) {
			let websiteIndustry = {};
			for (const industry in websiteIndustries) {
				if (websiteIndustries[industry].token !== allDefault) {
					websiteIndustry = websiteIndustries[industry];
				}
			}

			return websiteIndustry;
		},
		formatPercentage(val) {
			const utilsService = new UtilsService();
			return utilsService.formatPercentage(val);
		},
		getColour(type, subType, value, metric) {
			const coloursService = new ColoursService();
			return coloursService.getColour(type, subType, value, metric);
		},
		formatString(val, type, size, lengthComparison) {
			const utilsSerivce = new UtilsService();

			if (val.length > 20 && this.companyFull) {
				return utilsSerivce.limitTo(val, type, size, lengthComparison);
			}

			if (!this.companyFull) {
				return utilsSerivce.limitTo(val, type, size, lengthComparison);
			}

			return val;
		},
		closeAllInfo() {
			for (let website in this.websites) {
				this.websites[website].showInfo = false;
			}
		},
		checkDataNullValues(type, value, websiteData) {
			switch (type) {
				case "lh":
					return value === 0 || value === null;
				case "wv":
					if (value === null || (websiteData.lcp === 0 && websiteData.cls === 0 && websiteData.fid === 0)) {
						return false;
					} else {
						return true;
					}
				case "uptime":
					return value < 90;
			}
		},
		watchWindowResize() {
			if (window.innerWidth > 850) {
				this.companyFull = true;
				this.companyMedium = false;
				this.companyMini = false;
				this.companyMiniLong = false;
			}
			if (window.innerWidth > 550 && window.innerWidth < 850) {
				this.companyFull = false;
				this.companyMedium = true;
				this.companyMini = false;
				this.companyMiniLong = false;
			}
			if (window.innerWidth < 550) {
				this.companyFull = false;
				this.companyMedium = false;
				this.companyMini = true;
				this.companyMiniLong = false;
			}
			if (window.innerWidth < 370) {
				this.companyFull = false;
				this.companyMedium = false;
				this.companyMini = false;
				this.companyMiniLong = true;
			}
		},
	},
	mounted() {
		industryEventsEmitter.on(events.INDUSTRY, (payload) => {
			this.searchActive = false;
			this.selectedIndustry = payload;
			this.rankKey++;
			this.tableKey++;
		});

		statsEventsEmitter.on(events.TOGGLE_STATS, (payload) => {
			this.showStats = payload;
		});

		searchEventsEmitter.on(events.SEARCH, (payload) => {
			this.searchActive = true;
			this.searchTerm = payload;
		});

		searchEventsEmitter.on(events.CLEAR_SEARCH, () => {
			this.selectedIndustry = allDefault;
			this.searchActive = false;
			this.searchTerm = "";
		});

		searchEventsEmitter.on(events.SEARCH_EMPTY, () => {
			this.selectedIndustry = allDefault;
			this.searchActive = false;
		});

		loadingEventsEmitter.on(events.LOADING, (payload) => {
			this.loading = payload;
		});

		const header = document.getElementById("table-header");
		const sticky = header.offsetTop;

		window.onscroll = () => {
			if (window.scrollY > sticky) {
				this.headerSticky = true;
			} else {
				this.headerSticky = false;
			}
		};
	},
	created() {
		this.watchWindowResize();
		window.addEventListener("resize", this.watchWindowResize);
	},
};
</script>

<style lang="scss"></style>
