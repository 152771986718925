import mitt from "mitt";

// The events file contains events which are used to pass data between different components who do not have a parent/child relationship.
// Events are created using the 'mitt' library as in Vue3 the previous vue events API has been deprecated;

export const searchEventsEmitter = mitt();
export const industryEventsEmitter = mitt();
export const statsEventsEmitter = mitt();
export const webVitalsDataEventsEmitter = mitt();
export const loadingEventsEmitter = mitt();
export const modalEventsEmitter = mitt();
export const noDataEventsEmitter = mitt();

// event buses are then emitted and listening to using the following:
// emit - searchEventsEmitter.emit('event', payload);
// listen - searchEventsEmitter.on('event', (payload) => { 'do whatever' })

// events can be cleared but this clears all events by doing the following:
// searchEventsEmitted.all.clear();

// events constant is used to ensure the event string is the same and to avoid typos.
export const events = {
	SEARCH: "SEARCH",
	CLEAR_SEARCH: "CLEAR_SEARCH",
	SEARCH_EMPTY: "SEARCH_EMPTY",
	INDUSTRY: "INDUSTRY",
	TOGGLE_STATS: "TOGGLE_STATS",
	LOADING: "LOADING",
	MODAL_CHANGE: "MODAL_CHANGE",
	RESET_DEFAULT_INDUSTRY: "RESET_DEFAULT_INDUSTRY",
	TOGGLE_SHOW_INDUSTRIES: "TOGGLE_SHOW_INDUSTRIES",
	NO_DATA_AVAILABLE: "NO_DATA_AVAILABLE"
};
