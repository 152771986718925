<template>
	<div class="not-found">
		<Svg :svg-type="svgType.GENERAL" svg-name="wviLogo" svg-classes="sz-250 flc-white"></Svg>

		<div class="m-t-20">
			<h1>Page Not Found</h1>
            <h4>You seem to have discovered an unknown page, to get back to the Web Vitals Index <a href="/">click here</a></h4>
		</div>
	</div>
</template>

<script>
import Svg from "../Utilities/Svg.vue";
import { SvgService } from "@/services/svg.service";

export default {
	components: {
		Svg,
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
	},
};
</script>

<style></style>
