<template>
	<div class="controls" v-if="!data.noData">
		<div class="controls__industry" v-if="!searchActive">
			<h1>{{ industryLabel }}</h1>
			<Svg :svg-type="svgType.INDUSTRY" :svg-name="svgName" svg-classes="sz-28 flc-ok" :key="svgKey"></Svg>
		</div>

		<div class="controls__industry" :class="{ 'controls__industry--search': searchActive }" v-if="searchActive">
			<h1>Search results for:</h1>
			<h3>{{ searchTerm }}</h3>
			<button class="btn color--cta" :class="{ 'm-l-5': !searchActive }" @click="clearSearch()">Clear</button>
		</div>

		<div class="controls__stats">
			<div class="input-group">
				<input id="option1" name="option1" type="checkbox" v-model="stats" @change="toggleStats()" />
				<label for="option1">
					<p>STATS</p>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
// Component Imports
import Svg from "../../Utilities/Svg.vue";

// Services, Events etc.
import { industryEventsEmitter, webVitalsDataEventsEmitter, statsEventsEmitter, searchEventsEmitter, events } from "@/events/events";
import { allDefault } from "@/services/wvi.service";
import { SvgService } from "@/services/svg.service";

export default {
	components: {
		Svg,
	},
	props: ["allData"],
	data() {
		return {
			selectedIndustry: {},
			svgName: "",
			svgKey: 0,
			stats: false,
			searchTerm: "",
			searchActive: false,
		};
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
		data: function() {
			return this.allData;
		},
		industryLabel: function() {
			if (this.data.industries) {
				const industry = this.isObjEmpty(this.selectedIndustry) ? this.data.industries[allDefault] : this.selectedIndustry;
				return industry.label === "All" ? "Top 25" : industry.label;
			}
			return "";
		},
	},
	methods: {
		toggleStats() {
			statsEventsEmitter.emit(events.TOGGLE_STATS, this.stats);
		},
		getSvgName() {
			const svgService = new SvgService();
			this.svgName = svgService.getSvgName(this.selectedIndustry);
			this.svgKey++;
		},
		clearSearch(fromEvent) {
			this.searchActive = false;
			this.searchTerm = "";
			this.selectedIndustry = this.allData.industries[allDefault];
			this.getSvgName();
			if (!fromEvent) {
				searchEventsEmitter.emit(events.CLEAR_SEARCH);
			}
		},
		isObjEmpty(obj) {
			return Object.keys(obj).length === 0;
		},
	},
	mounted() {
		this.getSvgName();

		industryEventsEmitter.on(events.INDUSTRY, (payload) => {
			this.selectedIndustry = this.allData.industries[payload.token];
			this.getSvgName();
			this.searchActive = false;
			this.searchTerm = "";
		});

		searchEventsEmitter.on(events.SEARCH, (payload) => {
			this.searchActive = true;
			this.searchTerm = payload;
		});

		searchEventsEmitter.on(events.SEARCH_EMPTY, () => {
			this.clearSearch(true);
		});
	},
};
</script>

<style lang="scss"></style>
