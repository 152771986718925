export class UtilsService {


    /**
     * getWeekNumber
     * @returns week number of current year.
     */
    getWeekNumber(){

        const d = new Date();

        // Convert todays date to a UTC date to avoid issues with daylight savings.
        const utcDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

        // Set the date to the nearest thursday and also make sundays number 7.
        utcDate.setUTCDate(utcDate.getUTCDate() + 4 - (utcDate.getUTCDay() || 7));

        // Get frist day of year
        const yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));

        // Calculate full weeks to nearest Thursday - using thursday as this is the middle of the week.
        const weekNo = Math.ceil((((utcDate - yearStart) / 86400000) + 1 ) / 7);

        return weekNo;

    }

    /**
     * formatPercentage
     * @param {number} value 
     * @returns 
     */
    formatPercentage(value){
        return Math.round(value * 100);
    }

    /**
     * limitTo
     * @param {string} value - value of string to limit 
     * @param {string} type - type of string - e.g. domain or label of website.
     */
    limitTo(value, type, limitSize, lengthComparison){
        let limit = 0;
        if(type === 'domain') limit = limitSize;
        if(type === 'label') limit = limitSize;

        if(value.length > lengthComparison){
            return `${value.substr(0, limit)}<span class="sz-12 m-l-2 tc-white-1">[...]</span>`;
        }
        return value;
    }

    /**
     * ordinalSuffixOf - used to get the suffix of a number, mainly used for the rank displayed in social posts.
     * @param {number} i 
     * @returns 
     */
     ordinalSuffixOf(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

}