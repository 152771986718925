<template>
	<div class="position tt">
		<Svg
			v-if="change.rankDifference !== 0 && !change.isNewWebsite && !change.bigMover"
			:svg-type="svgType.GENERAL"
			svg-name="position_arrow"
			:class="{ position__negative: isNegative() }"
			:svg-classes="positionChangeClasses()"
		></Svg>
		<Svg
			v-if="change.rankDifference === 0 && !change.isNewWebsite && !change.bigMover"
			:svg-type="svgType.GENERAL"
			svg-name="equals"
			:svg-classes="positionChangeClasses()"
		></Svg>
		<div
			class="position__num"
			:class="{
				'position__num--negative': isNegative(),
				'position__num--positive': !isNegative(),
			}"
			v-if="change.rankDifference !== 0 && !change.isNewWebsite && !change.bigMover"
		>
			{{ formatPositionChangeNum() }}
		</div>
		<div class="tc-ok fw-b" v-if="change.isNewWebsite">
			NEW
		</div>
		<div class="tc-ok fw-b" v-if="change.bigMover">
			<Svg :svg-type="svgType.INDUSTRIES" svg-name="top" svg-classes="flc-ok sz-18"></Svg>
		</div>

		<!-- Tooltips -->
		<span class="tt-hover" v-if="change.rankDifference !== 0 && !change.isNewWebsite && !change.bigMover">
			{{ isNegative() ? "Relegated" : "Promoted" }} {{ change.rankDifference }}
			{{ change.rankDifference === 1 || change.rankDifference === -1 ? "place" : "places" }} this week
		</span>
		<span class="tt-hover" v-if="change.isNewWebsite">
			New entry to the WV Index this week
		</span>
		<span class="tt-hover" v-if="change.rankDifference === 0 && !change.isNewWebsite">
			Maintained rank position this week
		</span>
		<span class="tt-hover" v-if="change.rankDifference !== 0 && change.bigMover">
			Big Mover! Significant WV Index improvement
		</span>
	</div>
</template>

<script>
// Components
import Svg from "../../../Utilities/Svg.vue";

// Services
import { SvgService } from "@/services/svg.service";
import { WebVitalsIndexService, allDefault } from "@/services/wvi.service";

export default {
	components: {
		Svg,
	},
	props: ["website", "industry"],
	data() {
		return {
			change: {
				rankDifference: 0,
				isNewWebsite: false,
				bigMover: false,
			},
		};
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
	},
	methods: {
		positionChangeClasses() {
			let sizeClass = "sz-10";

			// Check if change value is negative and then set colour according.
			let positionColour = this.isNegative() ? "flc-error" : "flc-ok";
			// check if change value is 0 and update color;
			positionColour = this.change.rankDifference === 0 ? "flc-mid-4" : positionColour;

			if (this.change.rankDifference === 0) sizeClass = "sz-8";

			return `${sizeClass} ${positionColour}`;
		},
		formatPositionChangeNum() {
			return this.change.rankDifference < 0 ? this.change.rankDifference : `+${this.change.rankDifference}`;
		},
		isNegative() {
			return this.change.rankDifference < 0;
		},
	},
	mounted() {
		this.change = this.website.rankDifference;
	},
};
</script>

<style></style>
