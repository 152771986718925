<template>
	<div class="trophy">
		<Svg :svg-type="svgType.GENERAL" svg-name="trophy" :svg-classes="rankSvgClasses()"></Svg>
		<span class="trophy__rank" :class="{ 'sz-20': size }">
			{{ rank }}
		</span>
	</div>
</template>

<script>
//Components
import Svg from "../../../Utilities/Svg.vue";

//Services
import { SvgService } from "@/services/svg.service";

export default {
	props: ["rank", "size"],
	components: {
		Svg,
	},
	computed: {
		svgType: () => {
			const svgService = new SvgService();
			return svgService.SVGTYPES;
		},
	},
	methods: {
		rankSvgClasses() {
			let sizeClass = this.size ? `sz-${this.size}` : "sz-24";

			let rankTxt = "";

			if (this.rank === 1) rankTxt = "first";
			if (this.rank === 2) rankTxt = "second";
			if (this.rank === 3) rankTxt = "third";

			return `flc-${rankTxt} ${sizeClass}`;
		},
	},
};
</script>

<style></style>
